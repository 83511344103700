<template>
  <div :class="{'add-background':!building.image,'image-background':building.image}">
        <div v-if="!building.image">
          <div v-if="building.status">
            <span class="info">{{building.key}}</span>
          </div>
        </div>
        <div v-if="!building.image" class="tooltip-val">
          <div  v-if="building.status">
            <span>{{building.value}}</span>
          </div>
        </div>
        <a v-if="building.image" :href="building.image" data-fancybox="">
          <b-img  :src="building.image" fluid alt="Fluid image"></b-img>
           <span class="info">{{building.value}}</span>
        </a>
</div>
</template>

<script>
export default {
  props: {
    building: {
      type: Object,
      required: true,
    },
    // booked_plots: {
    //   type: Array,
    //   required: true
    // },
    // blocked_plots: {
    //   type: Array,
    //   required: true
    // },
  },
//  computed: {
//     booked_flat_details() {
//       return this.booked_plots.length > 0 ? this.booked_plots.map(b => b.key).includes(this.building.key) : ''
//     },
//     blocked_flat_details() {
//       return this.blocked_plots.length > 0 ? this.blocked_plots.map(b => b.key).includes(this.building.key) : ''
//     },
//  }
}
</script>
<style scoped>
.image-background{
  background: #AB3E91;
}
.add-background {
  background: transparent;
}
.info{
  color: #ffffff;
  display: block;
  padding: 4px 8px;
}

.tooltip-val{
  margin: 0 -8px -4px;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  color: #000;
  padding: 8px;
}
</style>